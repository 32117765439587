.footer-container {
  background-color: #445c81;
  padding-bottom: 4%;
}

.footer-text {
  font-family: 'FrutigerCondensed';
  padding-top: 1em;
  text-align: center;
  color: #324769;
  font-weight: bold;
}

.footer-icons-container {
  display: flex;
  width: 100%;
  align-content: space-between;
  justify-content: center;
  padding-bottom: 30px;
}

.footer-icons {
  width: 40% !important;
  color: #445c81;
}

.footer-icons-div {
  background-color: #324769;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.footer-icons-links {
  font-size: 22px;
  margin-right: 5px;
}
