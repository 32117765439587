.overview-container {
  max-width: 1000px;
  @media only screen and (max-width: 768px) {
    max-width: 600px;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  @media only screen and (max-width: 576px) {
    padding: 5% 12% 5% 14%;
    background-color: #e0e0e0;
  }
}

.overview-text-container__row {
  min-height: 460px;
  @media only screen and (max-width: 576px) {
    margin-right: 0;
    margin-left: 0;
  }
}

.overview-text-container__column {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  @media only screen and (max-width: 576px) {
    margin-bottom: 20%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.overview-text {
  font-family: 'FrutigerLightCondensed';
  color: #868585;
  font-size: 25px;
  @media only screen and (max-width: 576px) {
    font-size: 18px;
  }
}

.overview-text__last {
  margin: 0;
  @media only screen and (max-width: 768px) {
    margin-bottom: 2%;
  }
}

.overview-video-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  position: relative;
  height: 300px;

  @media only screen and (max-width: 992px) {
    // padding: 0 10%;
    // height: auto;
  }

  @media only screen and (max-width: 576px) {
    height: 200px;
    background-color: #bababa;
    margin-left: 2%;
    margin-right: 2%;
  }
}

.overview-video-image {
  margin-top: 10px;
}

.overview-video-playbutton__button {
  background: none;
  border: none;
  width: 100%;
  outline: none;
  position: absolute;
  top: 5;
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 992px) {
    width: 50%;
  }
}

.overview-video-playbutton__image {
  width: 40%;
  @media only screen and (max-width: 576px) {
    width: 60%;
  }
}

.overview-video__caption {
  font-family: 'FrutigerLightCondensed';
  text-align: center;
  font-size: 22px;
  color: #4d744d;
  margin: 0;
}

/* Modal styles */

.overview-video-modal__video {
  width: 100%;
}

.overview-video-modal__button {
  position: absolute;
  top: 2px;
  right: 2px;
  background: white;
  color: black;
  border: none;
  border-radius: 20px;
  &:hover {
    background: #868585;
    color: #b55d34;
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}
