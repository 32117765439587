/* Global styles */

@font-face {
  font-family: 'Frutiger';
  src: url('./Design Assets/Fonts/Frutiger.woff2') format('woff2'),
    url('./Design Assets/Fonts/Frutiger.woff') format('woff');
  font-style: normal;
}

@font-face {
  font-family: 'FrutigerBold';
  src: url('./Design Assets/Fonts/FrutigerBold.woff2') format('woff2'),
    url('./Design Assets/Fonts/FrutigerBold.woff') format('woff');
  font-style: bold;
}

@font-face {
  font-family: 'FrutigerCondensed';
  src: url('./Design Assets/Fonts/FrutigerCondensed.woff') format('woff'),
    url('./Design Assets/Fonts/FrutigerCondensed.woff2') format('woff2'),
    url('./Design Assets/Fonts/FrutigerCondensed.ttf') format('truetype'),
    url('./Design Assets/Fonts/FrutigerCondensed.svg') format('svg');
  font-style: normal;
  font-weight: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: 'FrutigerCondensed';
    src: url('./Design Assets/Fonts/FrutigerCondensed.svg') format('svg');
  }
}

@font-face {
  font-family: 'FrutigerLightCondensed';
  src: url('./Design Assets/Fonts/FrutigerLightCondensed.ttf')
    format('truetype');
}

@font-face {
  font-family: 'FrutigerBlackCondensed';
  src: url('./Design Assets/Fonts/FrutigerBlackCondensed.ttf')
    format('truetype');
  font-style: normal;
}

body {
  height: 100%;
}
