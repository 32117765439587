.hero-backgrounddiv {
  display: none;
  height: 460px;
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    display: block;
    background-color: #b55d34;
  }
  @media only screen and (min-width: 768px) {
    margin-top: -5%;
    display: block;
    background-color: #b55d34;
    clip-path: polygon(0 0, 100% 0, 100% 91%, 0 100%);
  }
}

.hero-video {
  width: 100%;
  height: 98.5%;
  object-fit: fill;
  @media only screen and (min-width: 768px) {
    clip-path: polygon(0 0, 100% 0, 100% 91%, 0 100%);
  }
}
