.contact-container {
  margin: 3% auto 5% auto;
  @media only screen and (max-width: 992px) {
    margin: 10% 0;
  }
  @media only screen and (min-width: 992px) {
    max-height: 460px;
    max-width: 1000px;
  }
}

.whatisai-solutions-div__background {
  background: linear-gradient(to bottom, white 10%, #e0e0e0 0%);
}

.map-mobile {
  padding: 0;
  @media only screen and (max-width: 992px) {
    margin-top: 5%;
    padding-left: 2%;
    padding-right: 2%;
  }
}
