.solutions-container {
  padding: 0;
  max-height: 460px;
}

.solutions-titles-row {
  max-width: 1100px;
  margin: 0 auto;
  justify-content: space-evenly;
}

.solutions-titles {
  margin: auto 0;
  font-size: 22px;
  font-family: 'FrutigerBlackCondensed';
  color: #514f4f;
}

.solutions-titlesColumn_Fraud {
  margin: auto 3%;
  @media only screen and (min-width: 768px) {
    margin: auto 0;
  }
}

.solutions-titlesCompliance {
  line-height: 20pt;
  margin-right: 4%;
}

.solution-images-container {
  background: linear-gradient(
    to bottom,
    #e0e0e0 0,
    #e0e0e0 35%,
    #fff 35%,
    #fff 65%,
    #e0e0e0 65%,
    #e0e0e0 100%
  );
}

.solutions-images-row {
  max-width: 1100px;
  margin: 0 auto;
  justify-content: space-evenly;
}

.solutions-images {
  clip-path: circle(50% at 50% 50%);
}

.solutions-imagesColumn__Fraud {
  margin: 0 2%;
}

.solutions-captions-row {
  max-width: 1100px;
  margin: 1% auto;
  justify-content: space-evenly;
}

.solutions-captions {
  font-family: 'FrutigerCondensed';
  font-size: 18px;
  font-weight: bold;
  color: #514f4f;
  padding: 0 5%;
}

.solutions-captions-divFraud {
  margin: 0 2% 3rem;
}

// Mobile layout styles

.solutions-container-mobile {
  padding: 0;
}

.solutions-container-row-titlesColumns {
  margin-top: 10%;
}

.solutions-titles-row-mobile {
  background-color: #e0e0e0;
  padding: 0 5%;
  padding: 0 5%;
  margin: 0;
}

.solutions-titles-mobile {
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-family: 'FrutigerBlackCondensed';
  color: #514f4f;
}

.solutions-titles-mobile__Compliance {
  padding: 0 5%;
}

.solutions-captions-mobile {
  margin: 0;
  text-align: center;
  font-family: 'FrutigerCondensed';
  font-size: 16px;
  color: #514f4f;
}

.solutions-captions-mobile__AI {
  padding: 0 15%;
}

.solution-captions-mobile__Fraud {
  padding: 0 11%;
}

.solution-captions-mobile__Compliance {
  padding: 0 12%;
  margin-bottom: 10%;
}

.solutions-images-mobile {
  width: 40%;
  margin: 2% 0;
}
