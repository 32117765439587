.form-column {
  position: relative;
}

.form-container {
  color: #878c9b;
  @media only screen and (max-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
}

.form-button {
  color: #fff;
  width: 90px;
  height: 50px;
  text-transform: uppercase;
  font-family: 'FrutigerCondensed';
  font-size: 20px;
  background-color: rgb(117, 132, 154);
  border-style: none;
  border-radius: 2px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    width: 80px;
    height: 40px;
  }
  bottom: 0;
  &:hover {
    color: #fff;
  }
  &:focus {
    outline: none;
  }
}

.form-label {
  font-family: 'FrutigerLightCondensed';
  @media only screen and (min-width: 1440px) {
    font-size: 22px;
  }
}

.form-text__error {
  padding-left: 10px;
  color: red;
}

.form-text__submit {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  color: green;
  @media only screen and (max-width: 576px) {
    position: relative;
  }
}
