.whatisai-wrapper {
  padding: 0 0 10px;
  background-color: #b55d34;
}

.whatisai-container {
  background: linear-gradient(to left, #081245 50%, #060f39 50%);
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  @media only screen and (max-width: 576px) {
    padding: 0;
  }
}

.whatisai-container-row {
  max-width: 1050px;
  margin: auto;
  @media only screen and (max-width: 576px) {
    margin: 0;
  }
}

.whatisai-leftcolumn {
  margin-bottom: 10%;
  padding-top: 10%;
  background-color: #060f39;
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 576px) {
    margin: 0;
  }
}

.whatisai-rightcolumn {
  margin: 10% 0;
  background-color: #081245;
  @media only screen and (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: 576px) {
    margin: 0;
  }
}

.whatisai-image {
  @media only screen and (max-width: 576px) {
    width: 80%;
  }
}

.whatisai-image__firstMobile {
  margin-left: 13%;
}

.whatisai-text-div__left {
  padding: 0 6%;
}

.whatisai-text-div__right {
  padding: 0 5%;
}

.whatisai-text {
  font-family: 'FrutigerCondensed';
  font-size: 22px;
  color: #c6ccd6;
  text-align: center;
  letter-spacing: 0.5px;
  line-height: 22pt;
  @media only screen and (max-width: 576px) {
    padding: 0 10%;
  }
}

// Clip path styles

@media only screen and (min-width: 768px) {
  .whatisai-wrapper {
    clip-path: polygon(0 9%, 100% 0, 100% 91%, 0% 100%);
  }
  .whatisai-container {
    clip-path: polygon(0 9%, 100% 0, 100% 91%, 0% 100%);
  }
  .whatisai-container-row {
    clip-path: polygon(0 9%, 100% 0, 100% 91%, 0% 100%);
  }
}
