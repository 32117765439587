.nav-fixed {
  background-color: white;
}

.nav-container {
  max-width: 1000px;
  padding: 0;
  @media only screen and (max-width: 768px) {
    max-width: 600px;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
}

.nav-navbar {
  padding: 0;
  justify-content: space-between;
  background-color: white;
}

.nav-logo {
  width: 178px;
}

.nav-container-links {
  align-items: center;
  align-self: stretch;
}

.nav-container-links__div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-link-custom {
  font-family: 'FrutigerCondensed';
  font-size: 16px;
  font-weight: bold;
  line-height: 20.81px;
  color: #334569 !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
  border-top: 3px solid transparent;
}

.nav-link-custom:hover {
  color: #b55d34 !important;
  cursor: pointer;
  border-top: 3px solid #b55d34;
}

.active {
  color: #b55d34 !important;
  border-top: 3px solid #b55d34;
}
