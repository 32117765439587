.map-container {
  @media only screen and (max-width: 768px) {
    max-width: 600px;
    margin: 0 auto;
  }
}

.map-address {
  font-family: 'FrutigerLightCondensed';
  color: #7f8695;
  font-size: 20px;
  margin: 5px auto -5px;
}

.map-phonenumber {
  font-family: 'FrutigerLightCondensed';
  color: #7f8695;
  font-size: 20px;
  margin: 0;
}

@media only screen and (max-width: 576px) {
  .map-address,
  .map-phonenumber {
    font-size: 16px;
  }
}
