.nav-container-mobile {
  padding: 0;
}

.nav-navbar-mobile {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 0 12%;
}

.nav-logo-mobile {
  width: 150px;
}

.section-title-text-mobile {
  font-family: 'FrutigerCondensed';
  color: #bababa;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-right: 10px;
}
